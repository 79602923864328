<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="false" />

      <b-tabs v-if="isList">
        <b-tab :title="pageTitle">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <h5 class="card-title">{{ pageTitle }} List</h5>
                </div>
                <div class="col-md-9">
                  <div class="row justify-content-end">
                    <div class="col-md-5 ml-auto mb-2">
                      <form @submit.prevent="doFilter()">
                        <div class="form-group mb-0">
                          <div class="input-group">
                            <input
                              v-model="filter.search"
                              type="text"
                              class="form-control"
                              placeholder="Search"
                            />
                            <div class="input-group-append">
                              <button class="btn btn-info" type="submit">
                                <i class="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-2">
                      <button
                        class="btn btn-info btn-block"
                        type="button"
                        @click="doReset"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table product-overview table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name <span class="text-danger mr5">*</span></th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="data.data === false">
                    <td colspan="99" class="text-center">
                      <Loader class="mt10 mb10" />
                    </td>
                  </tr>
                  <tr v-for="(v, k) in sortedData" :key="k">
                    <td>
                      {{ data.per_page * (data.current_page - 1) + k + 1 }}
                    </td>

                    <td>{{ v.ae_name }}</td>

                    <td>
                      <LabelStatus :status="v[statusKey]" />
                    </td>

                    <td>
                      <router-link
                        v-if="moduleRole('Edit')"
                        :to="{
                          name: $route.name,
                          params: { pageSlug: v[idKey] },
                        }"
                        class="text-inverse icon_action act_icon"
                        v-tooltip="'Edit'"
                      >
                        <i class="ti-marker-alt"></i>
                      </router-link>
                      <a
                        v-if="v.bul_id !== user.levelId && moduleRole('Edit')"
                        href="javascript:;"
                        class="text-inverse icon_action act_icon mx-1"
                        v-tooltip="'Change Status'"
                        @click="doChangeStatus(k, v)"
                      >
                        <i class="ti-settings"></i>
                      </a>
                      <a
                        v-if="v.bul_id !== user.levelId && moduleRole('Edit')"
                        href="javascript:;"
                        class="text-inverse icon_action act_icon"
                        v-tooltip="'Delete'"
                        @click="doDelete(k, v)"
                      >
                        <i class="ti-trash"></i>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="notFound">
                    <td colspan="99" class="text-center">
                      <h4>{{ notFound }}</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                  <div
                    class="pagination-flat float-right"
                    v-if="data.data !== false && data.data.length"
                  >
                    <Pagination
                      :data="data"
                      :limit="2"
                      @pagination-change-page="doPaginate"
                    >
                      <span slot="prev-nav">Previous</span>
                      <span slot="next-nav">Next</span>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>

        <template v-for="(vlang,klang) in lang">
          <b-tab :title="`Page Settings - ${vlang.text}`" :key="klang">
            <bo-card title="Hero Image" footer-tag="footer">
              <b-row class="gutter-2">
                <b-col md="3">
                  <bo-card-img
                    :src="uploader(((content['hero_' + vlang.value] || {}).value || {}).img)"
                    :title="((content['hero_' + vlang.value] || {}).value || {}).title"
                    :isTitleHtml="true"
                  >
                    <template #buttons>
                      <b-button
                        v-if="moduleRole('Edit')"
                        variant="secondary"
                        size="sm"
                        pill
                        @click="editHeroImage(content['hero_' + vlang.value])"
                        v-b-tooltip.hover="'Edit'"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </template>
                  </bo-card-img>
                </b-col>
              </b-row>
            </bo-card>
          </b-tab>
        </template>

        <template v-for="(vlangseo,klangseo) in lang">
          <b-tab :title="`SEO Settings - ${vlangseo.text}`" :key="`seo_${klangseo}`">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-10">
                    <h5 class="card-title">SEO Settings</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver 
                      v-slot="{ handleSubmit }" 
                      :ref="`VFormSeo${vlangseo.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'seo-setting',
                              content['seo_' + vlangseo.value],
                              `VFormSeo${vlangseo.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label :for="`seoTitle${vlangseo.value}`">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-input
                                      :id="`seoTitle${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`seoTitle${vlangseo.value}`"
                                      name="Title"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <p class="font-weight-bold">{{ ((content['seo_' + vlangseo.value] || {}).value || {}).title }}</p>
                                  </template>
                                </div>
                              </div>
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label>
                                    Description
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-textarea
                                      :id="`seoDescription${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                      rows="5"
                                    ></b-form-textarea>
                                    <VValidate
                                      :vid="`seoDescription${vlangseo.value}`"
                                      name="Description"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      rules="required|min:10|max:500"
                                    />
                                  </template>
                                  <template v-else>
                                    <div v-html="((content['seo_' + vlangseo.value] || {}).value || {}).description"></div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="moduleRole('Edit')">
                          <div class="col-12">
                            <hr />
                            <div class="text-right">
                              <template v-if="editFormId == `seo${vlangseo.value}`">
                                <button
                                  type="button"
                                  @click="editFormId = ''"
                                  class="btn btn-rounded btn-light mr-2"
                                >
                                  Cancel
                                </button>
                                <button type="submit" class="btn btn-rounded btn-primary">
                                  Save
                                </button>
                              </template>
                              <template v-else>
                                <button
                                  type="button"
                                  @click="editFormId = `seo${vlangseo.value}`"
                                  class="btn btn-rounded btn-primary"
                                >
                                  Ubah
                                </button>
                              </template>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </template>
      </b-tabs>
      <template v-else>
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <div class="card">
              <div class="card-body">
                <div
                  class="row"
                  v-if="Object.prototype.hasOwnProperty.call(row, 'event')"
                >
                  <div class="col-md-6">
                    <h5 class="card-title mb-3">Category Details</h5>
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">
                        Category Name
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input
                        v-model="row.event.ae_name"
                        type="text"
                        class="form-control"
                        placeholder="e.g. 42 km"
                      />
                      <VValidate
                        name="Name"
                        v-model="row.event.ae_name"
                        rules="required"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">
                        Distance
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input
                        v-model="row.event.ae_distance_description"
                        type="text"
                        class="form-control"
                        placeholder="e.g. 42,195 km"
                      />
                      <VValidate
                        name="Distance"
                        v-model="row.event.ae_distance_description"
                        rules="required"
                      />
                    </div>
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-12">
                    <hr />
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-12">
                    <b-tabs
                      v-if="Object.prototype.hasOwnProperty.call(row, 'langs')"
                    >
                      <b-tab v-for="(v, k) in row.langs" :key="k">
                        <template #title>
                          {{ v.name }} <span class="text-danger mr5">*</span>
                        </template>
                        <div class="card mb-0">
                          <div class="card-body px-0 pb-0">
                            <div class="row">
                              <div class="col-md-8">
                                <div class="form-group">
                                  <label class="control-label">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <input
                                    v-model="row.langs[k].event.ae_title"
                                    type="text"
                                    class="form-control"
                                    placeholder="e.g. 42k challenge for experienced and determined long-distance runner"
                                    @input="
                                      fillSeoTitle($event, row.langs[k].code)
                                    "
                                  />
                                  <VValidate
                                    :vid="'ae_title'+klang"
                                    name="Title"
                                    v-model="row.langs[k].event.ae_title"
                                    rules="required"
                                  />
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class="form-group">
                                  <label class="control-label">
                                    Category Description
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <ckeditor
                                    class="editor"
                                    :editor="editor"
                                    v-model="row.langs[k].event.ae_about"
                                    :config="editorConfig"
                                    @input="fillSeoDescription"
                                  ></ckeditor>
                                  <VValidate
                                    :vid="'about' + k"
                                    name="Description"
                                    v-model="row.langs[k].event.ae_about"
                                    rules="required"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-12">
                    <hr />
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-6">
                    <h5 class="card-title mb-3">Pricings and Registrations</h5>
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">
                        Indonesian Price
                        <span class="text-danger mr5">*</span>
                      </label>
                      <b-input-group prepend="Rp">
                        <input
                          v-model="row.event.ae_indonesian_price"
                          v-money="rules.money"
                          type="text"
                          class="form-control"
                          placeholder="e.g. 1.000.000"
                        />
                      </b-input-group>
                      <VValidate
                        name="Indonesian Price"
                        v-model="row.event.ae_indonesian_price"
                        rules="required"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">
                        Non-Indonesian Price
                      </label>
                      <b-input-group prepend="Rp">
                        <input
                          v-model="row.event.ae_non_indonesian_price"
                          v-money="rules.money"
                          type="text"
                          class="form-control"
                          placeholder="e.g. 1.100.000"
                        />
                      </b-input-group>
                      <VValidate
                        name="Non-Indonesian Price"
                        v-model="row.event.ae_non_indonesian_price"
                        rules=""
                      />
                    </div>
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-12">
                    <hr />
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-6">
                    <h5 class="card-title mb-3">Important Info</h5>
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">
                        Race Date
                        <span class="text-danger mr5">*</span>
                      </label>
                      <v-date-picker
                        locale="en"
                        :popover="{ visibility: 'click' }"
                        v-model="row.event.ae_race_date"
                        mode="date"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="px-2 py-1 form-control w-100 rounded"
                            :value="formatDateShort(inputValue)"
                            v-on="inputEvents"
                            :placeholder="
                              row.event.ae_race_date &&
                              $moment(
                                row.event.ae_race_date,
                                'YYYY-MM-DD'
                              ).diff($moment(new Date()), 'days') <= 0
                                ? formatDateShort(row.event.ae_race_date)
                                : 'Choose a date'
                            "
                          />
                        </template>
                      </v-date-picker>
                      <VValidate
                        name="Race Date"
                        v-model="row.event.ae_race_date"
                        rules="required"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">
                        Start Location
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input
                        v-model="row.event.ae_start_location"
                        type="text"
                        class="form-control"
                        placeholder="e.g. Gelora Bung Karno Sports Complex, Jakarta"
                      />
                      <VValidate
                        name="Start Location"
                        v-model="row.event.ae_start_location"
                        rules="required"
                      />
                    </div>
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label"> Finish Location </label>
                      <input
                        v-model="row.event.ae_finish_location"
                        type="text"
                        class="form-control"
                        placeholder="e.g. Plaza Timur, Gelora Bung Karno"
                      />
                      <VValidate
                        name="Finish Location"
                        v-model="row.event.ae_finish_location"
                        rules=""
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label"> Start Time </label>
                      <input
                        v-model="row.event.ae_start_time"
                        type="text"
                        class="form-control"
                        placeholder="e.g. 04:30 WIB"
                      />
                      <VValidate
                        name="Start Time"
                        v-model="row.event.ae_start_time"
                        rules=""
                      />
                    </div>
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label"> Start Line Cut Off </label>
                      <input
                        v-model="row.event.ae_limit_start_time"
                        type="text"
                        class="form-control"
                        placeholder="e.g. 04:40 WIB"
                      />
                      <VValidate
                        name="Start Line Cut Off"
                        v-model="row.event.ae_limit_start_time"
                        rules=""
                      />
                    </div>
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-12">
                    <hr />
                  </div>

                  <div class="w-100"></div>

                  <div class="col-md-12">
                    <b-tabs
                      v-if="Object.prototype.hasOwnProperty.call(row, 'langs')"
                    >
                      <b-tab v-for="(v, k) in row.langs" :key="k">
                        <template #title>
                          {{ v.name }}
                        </template>
                        <div class="card">
                          <div class="card-body px-0">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label class="control-label">
                                    Cut-Off Time
                                  </label>
                                  <input
                                    v-model="
                                      row.langs[k].event.ae_limit_end_time
                                    "
                                    type="text"
                                    class="form-control"
                                    :placeholder="v.placeholder.cutoff"
                                  />
                                  <VValidate
                                    :vid="'cot' + k"
                                    name="Cut-Off Time"
                                    v-model="
                                      row.langs[k].event.ae_limit_end_time
                                    "
                                    rules=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title mb-3">Entitlements</h5>

                <div class="row mb-4">
                  <div
                    v-for="(v, k) in (row.event || {}).ae_race_pack_img"
                    :key="k"
                    class="col-md-4"
                  >
                    <div class="row justify-content-end">
                      <div @click="removeRacePackImage(k)" class="col-md-3">
                        <div
                          class="d-flex justify-content-center align-items-center border border-danger cursor-pointer"
                          style="border-style: dashed"
                        >
                          <span class="text-danger">Remove</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label class="control-label">
                        Image {{k+1}}
                        <span class="text-danger mr5">*</span>
                      </label>
                      <Uploader
                        :readonly="!moduleRole('Edit')"
                        v-model="(row.event || {}).ae_race_pack_img[k].img"
                        :param="{ thumbnail: false }"
                        :squarePreview="true"
                        type="race_pack_image"
                        label="Image"
                        @data="v => (row.event || {}).ae_race_pack_img[k].fallback_img = v"
                      /> 
                      <VValidate :vid="'race_pack_img_'+k" name="Image" v-model="(row.event || {}).ae_race_pack_img[k].img" rules="required" />
                    </div> -->
                    <div class="form-group">
                      <label>
                        Name <span class="text-danger mr5">*</span>
                      </label>
                      <input
                        v-model="(row.event || {}).ae_race_pack_img[k].name"
                        type="text"
                        class="form-control"
                        placeholder="e.g. Bib Number"
                      />
                      <VValidate
                        :vid="'race_pack_img_name_' + k"
                        name="Name"
                        v-model="(row.event || {}).ae_race_pack_img[k].name"
                        rules="required"
                      />
                    </div>
                  </div>
                  <div @click="addRacePackImage" class="col-md-3">
                    <div
                      class="d-flex p-5 justify-content-center align-items-center border border-success cursor-pointer"
                      style="border-style: dashed"
                    >
                      <span class="text-success">Add Race Pack</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <div class="form-group">
                      <label> Sub Text </label>
                      <input
                        v-model="(row.event || {}).ae_race_pack_sub_text"
                        type="text"
                        class="form-control"
                        placeholder="e.g. Only for those who completed the race before the designated cut off time"
                      />
                      <VValidate
                        :vid="'race_pack_img_sub_text'"
                        name="Sub Text"
                        v-model="(row.event || {}).ae_race_pack_sub_text"
                        rules=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title mb-3">Winner Prizes</h5>

                <div v-if="Object.prototype.hasOwnProperty.call(row, 'langs')">
                  <template v-for="(v1, k1) in row.langs">
                    <div v-if="k1 == 0" :key="k1" class="table-responsive">
                      <table class="table custom_table_border">
                        <thead style="background-color: #e1ecf9">
                          <tr>
                            <th>#</th>
                            <th>
                              Category Name
                              <span class="text-danger mr5">*</span>
                            </th>
                            <!-- <th>Sub Category Name <span class="text-danger mr5">*</span></th> -->
                            <th>
                              Description <span class="text-danger mr5">*</span>
                            </th>
                            <th style="width: 70px;">Action</th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="
                            Object.prototype.hasOwnProperty.call(
                              row.langs[k1],
                              'prizes'
                            )
                          "
                        >
                          <tr
                            v-for="(v2, k2) in row.langs[k1].prizes"
                            :key="k2"
                          >
                            <td style="width: 70px">{{ k2 + 1 }}</td>
                            <td style="width: 230px">
                              <input
                                v-model="
                                  row.langs[k1].prizes[k2].ewp_category_name
                                "
                                type="text"
                                class="form-control"
                                placeholder="e.g. Open"
                              />
                              <VValidate
                                :vid="'prizeCategory-' + k1 + '-' + k2"
                                name="Category Name"
                                v-model="
                                  row.langs[k1].prizes[k2].ewp_category_name
                                "
                                rules="required"
                              />
                            </td>
                            <td style="width: 230px">
                              <ckeditor
                                class="editor"
                                :editor="editor"
                                v-model="
                                  row.langs[k1].prizes[k2].ewp_description
                                "
                                :config="editorConfig"
                              ></ckeditor>
                              <VValidate
                                :vid="'prizeDescription' + k1 + '-' + k2"
                                name="Description"
                                v-model="
                                  row.langs[k1].prizes[k2].ewp_description
                                "
                                rules="required"
                              />
                            </td>
                            <td>
                              <button
                                @click="removePrize(k1, k2)"
                                class="btn btn-outline-danger"
                              >
                                <i class="ti-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="99" class="text-center">
                              <button
                                type="button"
                                @click="addPrize(k1)"
                                class="btn btn-outline-success"
                              >
                                Add Prize Category
                              </button>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title mb-3">Prize terms and conditions</h5>

                <b-tabs
                  v-if="Object.prototype.hasOwnProperty.call(row, 'langs')"
                >
                  <b-tab v-for="(v1, k1) in row.langs" :key="k1">
                    <template #title>
                      {{ v1.name }}
                    </template>
                    <div class="table-responsive">
                      <table class="table custom_table_border">
                        <thead style="background-color: #e1ecf9">
                          <tr>
                            <th>#</th>
                            <th>
                              Title <span class="text-danger mr5">*</span>
                            </th>
                            <th>
                              Description <span class="text-danger mr5">*</span>
                            </th>
                            <th style="width: 70px;">Action</th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="
                            Object.prototype.hasOwnProperty.call(
                              row.langs[k1],
                              'adds'
                            )
                          "
                        >
                          <tr v-for="(v2, k2) in row.langs[k1].adds" :key="k2">
                            <td style="width: 70px">{{ k2 + 1 }}</td>
                            <td style="width: 230px">
                              <input
                                v-model="row.langs[k1].adds[k2].eai_title"
                                type="text"
                                class="form-control"
                                :placeholder="v1.placeholder.additional_title"
                              />
                              <VValidate
                                :vid="'addInfoTitle' + k1 + '-' + k2"
                                name="Title"
                                v-model="row.langs[k1].adds[k2].eai_title"
                                rules="required"
                              />
                            </td>
                            <td style="width: 230px">
                              <ckeditor
                                class="editor"
                                :editor="editor"
                                v-model="row.langs[k1].adds[k2].eai_description"
                                :config="editorConfig"
                              ></ckeditor>
                              <VValidate
                                :vid="'addInfoDescription' + k1 + '-' + k2"
                                name="Description"
                                v-model="row.langs[k1].adds[k2].eai_description"
                                rules="required"
                              />
                            </td>
                            <td>
                              <button
                                @click="removeAdditionalInfo(k1, k2)"
                                class="btn btn-outline-danger"
                              >
                                <i class="ti-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="99" class="text-center">
                              <button
                                type="button"
                                @click="addAdditionalInfo(k1)"
                                class="btn btn-outline-success"
                              >
                                Add term and condition
                              </button>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title mb-3">Images</h5>

                <div
                  class="row"
                  v-if="Object.prototype.hasOwnProperty.call(row, 'event')"
                >
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label"> Course Image </label>
                      <Uploader
                        :readonly="!moduleRole('Edit')"
                        :param="{ thumbnail: false }"
                        :squarePreview="false"
                        type="course_image"
                        upload-type="documents"
                        label="PDF"
                        :value="row.event.ae_course_img"
                        @data="inputCourseImg"
                        :remove-action="true"
                      />
                      <VValidate
                        name="Course Image"
                        v-model="row.event.ae_course_img"
                        rules=""
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">
                        Course Preview Image
                      </label>
                      <Uploader
                        :readonly="!moduleRole('Edit')"
                        v-model="row.event.ae_course_preview_img"
                        :param="{ thumbnail: false }"
                        :squarePreview="true"
                        type="course_preview_image"
                        label="Image"
                        @data="
                          (v) => (row.event.ae_course_preview_fallback_img = v)
                        "
                        :remove-action="true"
                      />
                      <VValidate
                        name="Course Preview Image"
                        v-model="row.event.ae_course_preview_img"
                        rules=""
                      />
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">
                        Banner Image
                        <span class="text-danger mr5">*</span>
                      </label>
                      <Uploader
                        :readonly="!moduleRole('Edit')"
                        v-model="row.event.ae_img"
                        :param="{ thumbnail: false }"
                        :squarePreview="true"
                        type="banner_image"
                        label="Image"
                        @data="(v) => (row.event.ae_fallback_img = v)"
                      />
                      <VValidate
                        name="Banner Image"
                        v-model="row.event.ae_img"
                        rules="required"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">
                        Banner Small Image
                        <span class="text-danger mr5">*</span>
                      </label>
                      <Uploader
                        :readonly="!moduleRole('Edit')"
                        v-model="row.event.ae_small_img"
                        :param="{ thumbnail: false }"
                        :squarePreview="true"
                        type="banner_small_image"
                        label="Image"
                        @data="(v) => (row.event.ae_small_fallback_img = v)"
                      />
                      <VValidate
                        name="Banner Small Image"
                        v-model="row.event.ae_small_img"
                        rules="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title mb-3">SEO Content</h5>

                <div
                  class="row"
                  v-if="Object.prototype.hasOwnProperty.call(row, 'event')"
                >
                  <div class="col-md-8">
                    <div class="form-group">
                      <label class="control-label">
                        Meta Title
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input
                        v-model="row.event.ae_seo_title"
                        type="text"
                        class="form-control"
                        placeholder="e.g. Jakarta Running Festival - 42 km"
                      />
                      <VValidate
                        name="Meta Title"
                        v-model="row.event.ae_seo_title"
                        rules="required"
                      />
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <label class="control-label">
                        Meta Description
                        <span class="text-danger mr5">*</span>
                      </label>
                      <textarea
                        v-model="row.event.ae_seo_description"
                        type="text"
                        class="form-control"
                        placeholder="e.g. Isn't simply about running! It's about sparking a city-wide festivity that..."
                        :rows="3"
                      ></textarea>
                      <VValidate
                        name="Meta Description"
                        v-model="row.event.ae_seo_description"
                        rules="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div
                  class="row mt-4"
                  v-if="Object.prototype.hasOwnProperty.call(row, 'event')"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">
                        Status
                        <span class="text-danger mr5">*</span>
                      </label>
                      <div>
                        <InputRadio
                          v-model="row.event[statusKey]"
                          name="status"
                          option="Y"
                        />
                        <InputRadio
                          v-model="row.event[statusKey]"
                          name="status"
                          option="N"
                        />
                      </div>
                      <VValidate
                        name="Status"
                        v-model="row.event[statusKey]"
                        :rules="mrValidation[statusKey]"
                      />
                    </div>
                  </div>
                  <div class="w-100">
                    <hr />
                  </div>
                  <div class="col-md-12 text-right">
                    <router-link
                      :to="{ name: $route.name }"
                      class="btn btn-rounded btn-light mr-2"
                      >Cancel</router-link
                    >
                    <button type="submit" class="btn btn-rounded btn-info">
                      {{ isAdd ? "Submit" : "Update" }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>

      <ValidationObserver v-slot="{ handleSubmit }" ref="VFormHeroImage">
        <b-modal
          id="heroImageModal"
          size="md"
          title="Edit Hero Image"
          @ok.prevent="
            handleSubmit(
              updateContent(
                'hero-image',
                inputHeroImage,
                'VFormHeroImage',
                'heroImageModal'
              )
            )
          "
        >
          <template #default>
            <div class="infoHTML"></div>
            <b-form 
              @submit.prevent="
                  handleSubmit(
                    updateContent(
                      'hero-image',
                      inputHeroImage,
                      'VFormHeroImage'
                    )
                  )
                "
            >
              <b-row>
                <b-col md="12">
                  <BoField 
                    label="Sub Title"
                    placeholder="e.g. ABOUT"
                    v-model="((inputHeroImage || {}).value || {}).sub_title"
                    :rules="mrValidation.sub_title"
                  />
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Title"
                    placeholder="e.g. Jakarta Running Festival 2024"
                    v-model="((inputHeroImage || {}).value || {}).title"
                    :rules="mrValidation.title"
                  />
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="page_title_banner"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img"
                      :rules="mrValidation.hero_image.img"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Small Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img_small"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="page_title_banner_small"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img_small = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img_small"
                      :rules="mrValidation.hero_image.img_small"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Alt Image"
                    placeholder="e.g. Hero Image"
                    v-model="((inputHeroImage || {}).value || {}).alt_img"
                    :rules="mrValidation.alt_img"
                  />
                </b-col>
                <hr />
              </b-row>
            </b-form>
          </template>

          <template #modal-footer="{ ok, cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="info" @click="ok()">
              Submit
            </b-button>
          </template>
        </b-modal>
      </ValidationObserver>
    </div>
  </div>
</template>
  
<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";
import debounce from "lodash/debounce";

import BoCard from '@/components/BoCard.vue'
import BoCardImg from '@/components/BoCardImg.vue'

import assign from "lodash/assign";

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    ckeditor: CKEditor.component,
    BoCard,
    BoCardImg
    // StaticSeoAtom
  },
  data() {
    return {
      rules: {
        money: {
          decimal: ",",
          thousands: ".",
          prefix: "",
          suffix: "",
          precision: 0,
          masked: false,
        },
      },
      statusKey: "ae_is_active",
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        },
      ],
      staticSeo: {},
      idKey: "ae_slug",
      editor: ClassicEditor,
      editorConfig: {
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "fontColor",
            "link",
            "numberedList",
            "bulletedList",
          ],
        },
      },
      content: [],
      editFormId: "",
      inputHeroImage: {},
    };
  },
  computed: {
    sortedData() {
      var data = this.data.data;
      console.log("data", data);
      var sorted = (data || []).sort((a, b) => {
        var keyA = a.ae_id,
          keyB = b.ae_id;

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      return sorted;
    },
  },
  methods: {
    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },
    inputCourseImg(pathfile) {
      this.row.event.ae_course_img = pathfile;
    },
    fillSeoTitle: debounce(function (e, langCode) {
      if (langCode == "id") return;
      this.row.event.ae_seo_title = `${e.target.value.substr(0, 160)}`;
    }, 500),
    fillSeoDescription: debounce(function (value) {
      /* eslint-disable no-control-regex  */
      /* eslint-disable no-useless-escape  */
      let el = document.createElement("div");
      el.innerHTML = value;

      if (el.textContent.length >= 160) {
        this.row.event.ae_seo_description = el.textContent.match(
          new RegExp("^.{0,160}")
        )[0];
      } else {
        this.row.event.ae_seo_description = el.textContent;
      }
    }, 500),
    addTermsConditions(index1) {
      this.row.langs[index1].terms_conditions.splice(
        this.row.langs[index1].terms_conditions.length,
        0,
        {
          etc_description: "",
        }
      );
    },
    removeTermsConditions(index1, index2) {
      this.row.langs[index1].terms_conditions.splice(index2, 1);
    },
    addPrize(index1) {
      this.row.langs[index1].prizes.splice(
        this.row.langs[index1].prizes.length,
        0,
        {
          ewp_category_name: "",
          ewp_sub_category_name: "",
          ewp_description: "",
        }
      );
    },
    removePrize(index1, index2) {
      this.row.langs[index1].prizes.splice(index2, 1);
    },
    addRacePackDetail(index1) {
      this.row.langs[index1].race_pack_details.splice(
        this.row.langs[index1].race_pack_details.length,
        0,
        {
          erpd_name: "",
          erpd_description: "",
        }
      );
    },
    removeRacePackDetail(index1, index2) {
      this.row.langs[index1].race_pack_details.splice(index2, 1);
    },
    addRacePackImage() {
      this.row.event.ae_race_pack_img.splice(
        this.row.event.ae_race_pack_img.length,
        0,
        {
          name: "",
          img: "",
          fallback_img: "",
        }
      );
    },
    removeRacePackImage(index) {
      this.row.event.ae_race_pack_img.splice(index, 1);
    },
    addAdditionalInfo(index1) {
      this.row.langs[index1].adds.splice(
        this.row.langs[index1].adds.length,
        0,
        {
          eai_title: "",
          eai_description: "",
        }
      );
    },
    removeAdditionalInfo(index1, index2) {
      this.row.langs[index1].adds.splice(index2, 1);
    },
    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      console.log('inputheroimage', this.inputHeroImage)

      this.$bvModal.show('heroImageModal')
    },
  },
  mounted() {
    this.apiGet();

    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
  watch: {
    $route() {
      this.apiGet();
    },
  },
};
</script>

<style>
.custom_table_border,
.custom_table_border th,
.custom_table_border td {
  border: 1px solid #ddd;
}
</style>